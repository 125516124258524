<template>
  <div class="h-screen flex items-center justify-center bg-loginImg bg-center">
    <form
      class="grid grid-cols-2 md:w-1/2 lg:w-1/4 bg-white bg-opacity-30 rounded-lg"
      method="post"
      style="backdrop-filter: blur(3px)"
    >
      <div
        class="title col-span-full text-lg text-center font-bold p-2 bg-white rounded-t-lg mb-5"
      >
        <h4>Login / Register</h4>
      </div>
      <div class="col-span-full text-center">
        <div class="grid grid-cols-3">
          <span class="pr-2">Username:</span>
          <input
            class="rounded focus:outline-none pl-2 col-span-2 mr-3"
            type="text"
            name="username"
            v-model="model_username"
          />
        </div>
        <br />
      </div>
      <div class="col-span-full  text-center">
        <div class="grid grid-cols-3">
          <span class="pr-2">Password:</span>
          <input
            class="rounded focus:outline-none pl-2 col-span-2 mr-3"
            type="password"
            name="password"
            v-model="model_password"
            required
          />
        </div>
      </div>
      <div class="mt-5 col-span-full text-center">
        <button
          class="w-1/3 p-1 rounded border border-collabBlue hover:border-collabLightBlue hover:bg-collabLightBlue bg-collabBlue text-white mr-2 focus:outline-none"
          type="submit"
          formaction="/login"
          required
        >
          Login
        </button>
        <button
          class="w-1/3 p-1 rounded border border-collabBlue hover:border-collabLightBlue hover:bg-collabLightBlue bg-collabBlue text-white ml-2 focus:outline-none"
          type="submit"
          formaction="/register"
          required
        >
          Register
        </button>
        <br />
      </div>
      <div id="error-message" class="w-full text-sm col-span-full">
        {{ errormsg }}
      </div>
      <div>
        {{ debugtext }}
      </div>
    </form>
    <!-- <button class="submit-btn" @click="inlogin">Inpage-Login</button> -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      errormsg: "",
      model_username: "",
      model_password: "",
      debugtext: "",
    };
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const info = urlParams.get("info");
    if (info) {
      this.errormsg = info;
    }
  },
  methods: {
    inlogin() {
      axios
        .post("/login-inpage", {
          username: this.model_username,
          password: this.model_password,
        })
        .then((r) => {
          console.log("response from login" + r.data);
          axios.get("/user").then((r) => {
            console.log("response from user:" + r.data);
            this.debugtext = r.data;
          });
        });
    },
  },
};
</script>

<style scoped>
#error-message {
  color: #e91e63;
  display: block;
  margin: 10px;
  font-size: large;
  max-width: fit-content;
}
</style>